<template>
  <nav :class="`navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ${ trialDays > 0 && isAccountInTrialMode ? 'fix-top-override' : ''}`">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <router-link :to="{ name: 'home', query: {orgId: this.current_xero_organisation}}" class="navbar-brand brand-logo mr-5">
        <img src="@/assets/images/paypredict-logo-white.svg" class="mr-2 no-print" alt="logo" />
        <img src="@/assets/images/paypredict-logo.svg" class="mr-2 print-only" alt="logo" />
        <!-- <img src="@/assets/images/ONLY-PayPredict-Logo-WHITE.png" class="mr-2 no-print" alt="logo" />
        <img src="@/assets/images/PayPredict_Logo.png" class="mr-2 print-only" alt="logo" /> -->
      </router-link>
    </div>


    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <router-link :to="{ name: 'home', query: {orgId: $route.query.orgId} }" class="btn btn-primary text-white mx-2">
        <i class="ti-home text-white mx-2"></i>
      </router-link>

      <div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle text-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="mx-2">{{ $store.state.authed_xero.organisation_name }}</span>
          <i class="ti-menu mr-2 mx-0"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right no-print">
          <ul class="list-group nav">
            <!-- <li class="nav-item">
              <router-link to="/dashboard/" class="nav-link">
              <strong>
              <i class="ti-user menu-icon"></i>
              {{ $store.state.authed_xero.organisation_name }}
              </strong>
              </router-link>
            </li> -->

            <li class="nav-item">
              <router-link :to="{ name: 'home', query: {orgId: this.current_xero_organisation}}" class="nav-link"  id="LinkNavDashboard">
                <i class="ti-home menu-icon"></i>
                <span class="menu-title">Dashboard</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'quickcheck', query: {orgId: $route.query.orgId} }" class="nav-link" style="font-weight:bold">
                <i class="ti-search menu-icon"></i>
                <span class="menu-title"><strong>QuickCheck (New)</strong></span>
              </router-link>
            </li>
            
            <li class="nav-item">
              <router-link :to="{ name: 'portfolioinsights', query: { orgId: $route.query.orgId} }" class="nav-link">
                <i class="ti-bar-chart menu-icon"></i>
                <span class="menu-title">Portfolio Insights</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'customerlist', query: {orgId: this.current_xero_organisation}}" class="nav-link" id="LinkNavCustomerList">
                <i class="ti-list menu-icon"></i>
                <span class="menu-title">Customer List</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'overdueinvoicelistdetail', query: {orgId: this.current_xero_organisation}}"  class="nav-link" id="LinkNavCOverdueInvoices">
                <i class="ti-thumb-down menu-icon"></i>
                <span class="menu-title">Overdue Invoices</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'invite', query: {orgId: this.current_xero_organisation}}" class="nav-link"  id="LinkNavSendInvites">
                <i class="ti-email menu-icon"></i>
                <span class="menu-title">Send Invites</span>
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" v-on:click="refresh_xero" class="nav-link"  id="LinkNavRefreshXero">
                <i class="ti-reload menu-icon"></i>
                <span class="menu-title">Refresh Data From Xero</span>
              </a>
            </li>
            <li>
              <hr />
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'settings', query: {orgId: this.current_xero_organisation}}" class="nav-link">
                  <i class="ti-user menu-icon"></i>
                  <span class="menu-title">Account Settings</span>
              </router-link>
            </li>
            <li>
              <hr />
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="downloadRiskReport">
                <i class="fas fa-file-csv menu-icon"></i>
                <span class="menu-title">Download Risk Report</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$emit('printPage')">
                <i class="fas fa-file-image menu-icon"></i>
                <span class="menu-title">Save Page for Sharing</span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                data-toggle="modal"
                data-target="#exampleModal"
                @click="activateSharingLink"
              >
                <i class="ti-share menu-icon"></i>
                <span class="menu-title">Share Report</span>
              </a>
            </li> -->
            <li>
              <hr />
            </li>
            <li class="nav-item">
              <a @click.prevent="switchAccount" class="nav-link">
                  <i class="ti-back-right menu-icon"></i>
                  <span class="menu-title">Switch Accounts</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/logout/" class="nav-link">
                  <i class="ti-back-left menu-icon"></i>
                  <span class="menu-title">Logout</span>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>


    <PopupMessage
      v-if="this.isLoading"
      v-bind:popup_message="'Just refreshing your data from Xero, hold tight.'"
    ></PopupMessage>

    <!-- Sharing Link Modal
    When I tried to put the modal code in a separate component it would not display, so that's why it's in this component.
    -->
    <div class="modal fade" data-backdrop="" id="exampleModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sharing Link</h4>
            <button
              type="button"
              class="close btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              This link gives read-only access to the dashboard, customer list,
              and overdue invoices. The person using the link cannot send emails
              or make any settings changes.
            </div>
            <br />
            <div id="shareLinkText">
              <input
                @click="$event.currentTarget.select()"
                type="text"
                :value="share_link"
                readonly
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>


  </nav>
</template>

<script>
import PopupMessage from "@/components/PopupMessage.vue"
import { mapGetters } from 'vuex'
import api from "@/api.js"
import EFXMixins from '@/mixins/efx.js'

export default {
  name: "Header",
  components: {
    PopupMessage
  },
  props: {
    is_app: Boolean
  },
  mixins: [
    EFXMixins
  ],
  data: function() {
    return {
      isLoading: false,
      share_link: "Loading your link...",
      password_reset: "",
      login: {
        email: "",
        password: ""
      },
      reset: {
        email: ""
      }
    };
  },
  computed: {
    ...mapGetters(["current_xero_organisation", "is_view_only_user", "v2AccountId"]),

    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },
    freshReportAvailable() {
      if (!this.$store.state.contacts_report.report_id) {
        return false;
      }
      if (!this.$store.state.authed_xero.current_report_id) {
        return false;
      }
      return (
        this.$store.state.authed_xero.current_report_id !=
        this.$store.state.contacts_report.report_id
      );
    },
    clientDashUrl() {
      const params = (new URL(document.location)).searchParams;
      const clientDash = params.get('clientdash');
      if (clientDash) {
        return 'https://evenly.com.au/paypredict/' + clientDash
      }
      return null
    },
    isAccountInTrialMode() {
      return this.$store.state.connected_account_is_in_trial_mode
    },
    trialDays() {
      return this.$store.state.trial_days
    },
  },
  methods: {
    switchAccount() {
      const url = new URL(window.location.href)
      url.searchParams.delete('orgId')
      window.location.href = "/pp"
    },
    set_tab(value) {
      this.$emit("navigate", value);
    },
    // clear_current_org() {
    //   this.$store.dispatch('deselect_current_organisation')
    // },
    async refresh_xero() {
      this.isLoading = true;
      await this.$store.dispatch("resync_and_reload_contacts_report");
      this.isLoading = false;
    },
    async reload_contacts_report() {
      await this.$store.dispatch("reload_contacts_report");
    },
    activateSharingLink() {
      api
        .get(`integrations/authed-xero/` + this.current_xero_organisation + `/@@sharing-link`)
        .then(
          (resp) => (this.share_link = resp.data.link + window.location.hash)
        );
    },
    downloadRiskReport() {

      api.get(`v2/accounts/${this.v2AccountId}/contacts/@@paypredict`, { params: { limit: 'all', paypredict_track: true, offset: 0 } } )
        .then(
          ({ data }) => {
            const today  = new Date();
            const filename = 'Paypredict Risk Lists.csv'

            const lists = data.data.items.map((row) => {
              return {
                'Name': row.name || '',
                'Legal Name': row.equifax_file ? row.equifax_file.entity_name : '',
                'ABN': row.best_abn,
                'ACN': row.equifax_file ? row.equifax_file.acn : '',
                'EVENLY Score': row.customer_score_category ? (row.customer_score_category.charAt(0).toUpperCase() + row.customer_score_category.slice(1)) : '',
                'EFX Current Risk Category': this.getCategory(row.prs || ''),
                'EFX Current Score': row.prs || '',
                'EFX Previous Score': row.last_prs || '',
                'Trend': this.getTrend(row.prs_trend),
                'Date of Report': `${today.getDate()}/${(today.getMonth()+1)}/${today.getFullYear()}`
              };
            })

            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = Object.keys(lists[0])

            let csv = lists.map(row => header.map(fieldName =>
            JSON.stringify(row[fieldName], replacer)).join(','))
            csv.unshift(header.join(','))
            csv = csv.join('\r\n')

            // Create link and download
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
    },
  },
};
</script>

<style scoped lang="scss">
$background: #fff;
$color: #333;
$focus-background: #eee;
$focus-color: #4d82ad;


#shareLinkText {
  word-break: break-all;
  background: #f2fcff;
  border: thin solid #e3e3e3;
  border-radius: 4px;
}
#shareLinkText input {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px;
}


// login form

.form-inline {
  margin: -5px 0 0 0;
  input,
  button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;

    height: 40px;
    font-size: 14px;
  }
  input {
    width: 150px;
    background-color: $background;
    border: 1px solid $focus-color;
  }
  button {
    background-color: none;
    border: 1px solid $background;
    color: $background !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: translate(0px, 0px);
    &:hover {
      transform: translate(0px, -5px);
    }
    &:active {
      transform: translate(0px, -2px);
    }
  }
}

.page_navigation {
  z-index: 1200;
  position: relative;
}
.account_select {
  z-index: 1000;
  position: relative;
}
ul.nav {
  background: $background;
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    color: $color;
    background: $background;
    display: block;
    float: right;
    // padding: 5px 10px;
    // margin-right: 10px;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    border-radius: 3px;
    &.thin {
      padding: 5px 0px;
      hr {
        margin: 0;
      }
      &:hover,
      &:focus-within {
        background: $background;
        cursor: default;
      }
    }
    a {
      color: $color;
      display: block;
      width: 100%;
      &:hover {
        color: $focus-color;
      }
    }
    &:hover,
    &:focus-within {
      background: $focus-background;
      cursor: pointer;
    }
    &:focus-within a {
      outline: none;
    }
    ul {
      text-align: left;
      min-width: 200px;
      background: $background;
      visibility: hidden;
      opacity: 0;
      min-width: 8rem;
      position: absolute;
      transition: all 0.5s ease;
      margin-top: 2px;
      padding-top: 5px;
      right: 0;
      display: none;
      border-radius: 3px;
      -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
      li {
        clear: both;
        width: 100%;
        min-width: 200px;
      }
    }
    &:hover > ul,
    &:focus-within > ul,
    ul:hover,
    ul:focus {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
}

// header

.loading a {
  text-decoration: underline;
}

.fix-top-override {
  top: 65px;
}
</style>
